import * as React from "react";
import {
  Container,
  Form,
  FormControl,
  InputGroup,
  Row,
  Button,
} from "react-bootstrap";
import { navigate } from "gatsby";
import $ from "jquery";
import Layout from "../components/Layout";
import { saveUser } from "../utils/auth";

function Timer() {
  const [seconds, setSeconds] = React.useState(59);
  React.useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds("00:00");
    }
  });
  return (
    <div className="mb-4 text-start ms-xl-5 ms-lg-5 ms-3 ps-2">
      <span>({seconds})</span>
    </div>
  );
}
export default function MerchantConfirmationPage() {
  const url_string =
    typeof window !== "undefined"
      ? new URLSearchParams(window.location.search).get("phonenumber")
      : "";
  const handleSubmit = (e) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let total =
      document.querySelector("#element0")?.value +
      document.querySelector("#element1")?.value +
      document.querySelector("#element2")?.value +
      document.querySelector("#element3")?.value;
    var raw = JSON.stringify({
      Code: total,
      PhoneNumber: "+" + url_string.replace(" ", ""),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/api/Account/ConfirmPhone`, requestOptions)
      .then((response) => {
        return response?.text() ?? "";
      })
      .then((result) => {
        var json = JSON.parse(result);
        if (
          json?.Message === "phone number not found" ||
          json?.Message === "Wrong Code"
        ) {
          document.querySelector(".confirmerror").style.display = "block";
        } else {
          document.querySelector(".confirmerror").style.display = "none";
        }
        if (json.access_token) {
          saveUser({
            name: json?.userName ?? "",
            email: json?.userName ?? "",
            token: json.access_token ?? "",
            type: window.MarchentInfo?.type,
            tagy: window.MarchentInfo?.tagy,
          });
          if (window.backToAddLocation) {
            navigate("/add-location");
          } else navigate("/app/dashboard");

          localStorage.setItem("loginaccesstokec", json.access_token);
          localStorage.setItem("loginemail", json.userName);
          document.querySelector(".registerbutton").style.display = "none";
          document.querySelector(".loginbutton").style.display = "none";
          document.querySelector(".logoutbutton").style.display = "block";
          document.querySelector(".welocmetext").style.display = "block";
        }
      })
      .catch((error) => console.log("error", error));
    e.preventDefault();
  };
  const elemRefs = [];
  const autoTab = (e) => {
    const BACKSPACE_KEY = 8;
    const DELETE_KEY = 46;
    let tabindex = $(e.target).attr("data-index") || 0;
    tabindex = Number(tabindex);
    let elem = null;
    if (e.keyCode === BACKSPACE_KEY) {
      elem = tabindex > 0 && elemRefs[tabindex - 1];
    } else if (e.keyCode !== DELETE_KEY) {
      elem = tabindex < elemRefs.length - 1 && elemRefs[tabindex + 1];
    }
    if (elem) {
      elem.current.focus();
    }
  };
  function fetchClassName(index) {
    let result;
    switch (index) {
      case "0": {
        result = "first";
        break;
      }
      case "1": {
        result = "second";
        break;
      }
      case "2": {
        result = "third";
        break;
      }
      case "3": {
        result = "last";
        break;
      }
    }
    return result;
  }
  const Input = (props) => {
    const ref = React.createRef();
    elemRefs.push(ref);
    return (
      <FormControl
        data-index={props.index}
        id={"element" + props.index}
        ref={ref}
        maxLength={1}
        className="ps-2"
        onKeyUp={props.autoTab}
      />
    );
  };

  const blocks = Array.from({ length: 4 }, (element, index) => (
    <InputGroup className="mb-2 w-auto ms-0">
      <Input
        key={index}
        index={index}
        autoTab={autoTab}
        id={fetchClassName(index)}
      />
    </InputGroup>
  ));

  return (
    <Layout>
      <main>
        <Container>
          <section>
            <div className="col-xl-5 col-lg-5 col-md-12 pt-3 mx-auto text-center">
              <h1 className="fs-48 fw-800 mb-5">Register as new Merchant</h1>
              <img src="../img/shoe-shop.svg" alt="register" />
              <h5 className="fw-bold fs-36 mt-4">Register</h5>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12 pb-5 mx-auto text-center">
              <form className="mt-4" onSubmit={handleSubmit}>
                <p
                  className="confirmerror"
                  style={{ display: "none", fontSize: "11px", color: "red" }}
                >
                  Wrong Code please check again.
                </p>

                <div className="col-xl-12 col-md-12 register-form">
                  <Row className="nav-link justify-content-center d-flex">
                    {blocks}
                  </Row>
                </div>
                <Timer />
                <Button
                  variant="primary"
                  className="w-100 fs-14 fw-bold h-52"
                  type="submit"
                >
                  Submit
                </Button>
              </form>
            </div>
          </section>
        </Container>
      </main>
    </Layout>
  );
}
